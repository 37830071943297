/* eslint-disable */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../containers/layout';
import HeaderPage from '../components/HeaderPage';
import Paragraph from '../components/Paragraph';
import VisualComposer from '../components/VisualComposer';
import Map from '../components/Map';
import FeedWithPagination from '../components/FeedWithPagination';
import AccordionList from '../components/AccordionList';
import Accordion from '../components/Accordion';

const ComitatiLocaliArchive = ({ data, pageContext }) => {
  const {
    coverImage,
    coverImageMobile,
    title,
    label,
    subtitle,
    content,
    contentAccordion,
    seoMetaTags
  } = data.datoCmsComitatiLocaliArchive;

  const {
    regions
  } = { regions: data.allDatoCmsRegioni.nodes };

  // const localNews = data.allDatoCmsLocalNews.nodes;

  const regionsNord = [];
  const regionsCentro = [];
  const regionsSud = [];
  // const { slugArchive } = { slugArchive: data.datoCmsComitatiLocaliArchive.slug };

  regions.forEach((region) => {
    if (region.zone === 'nord') {
      regionsNord.push(region);
    } else if (region.zone === 'centro') {
      regionsCentro.push(region);
    } else if (region.zone === 'sud-e-isole') {
      regionsSud.push(region);
    }
  });
  return (
    <Layout
      seoMetaTags={seoMetaTags}
      pathname={pageContext.pathname}
      communicationBar={pageContext.communicationBar}
      pageType="Commitees Archive"
      canonical={pageContext.canonical}
    >
      <HeaderPage
        title={title}
        coverImage={coverImage}
        coverImageMobile={coverImageMobile}
        model={label}
        isRoot
      />
      <div className="page-wrapper">
        <div className="page-wrapper__paragraph">
          <Paragraph
            subtitle={subtitle}
            content={content}
            contentAccordion={contentAccordion}
            marginTop="zero"
            marginBottom="zero"
          />
        </div>
      </div>
      {
        regions && (
          <Map
            regions={regions}
            marginBottom="piccolo"
          />
        )
      }
      <section className="comitati__accordions">
        <div className="comitati__accordions-wrapper">
          {
            regionsNord && (
              <div className="comitati__accordion">
                <Accordion isActive title="Nord">
                  <AccordionList
                    list={regionsNord}
                  />
                </Accordion>
              </div>
            )
          }
          {
            regionsCentro && (
              <div className="comitati__accordion">
                <Accordion title="Centro">
                  <AccordionList
                    list={regionsCentro}
                  />
                </Accordion>
              </div>
            )
          }
          {
            regionsSud && (
              <div className="comitati__accordion">
                <Accordion title="Sud ed isole">
                  <AccordionList
                    list={regionsSud}
                  />
                </Accordion>
              </div>
            )
          }
        </div>
      </section>

      {
        data && data.datoCmsComitatiLocaliArchive && (
          <VisualComposer
            components={data.datoCmsComitatiLocaliArchive.visualComposer}
          />
        )
      }
      {/* Lista News Locali mixate in ordine dal più recente */}
      {
        /*
        localNews.length > 0 && (
          <FeedWithPagination slugArchive={slugArchive} allNews={localNews} />
        )
        allDatoCmsLocalNews(
      sort: {fields: publicationDate, order: DESC}
    ) {
      nodes {
        id
        title
        slug
        publicationDate
        comitato {
          id
          title
          slug
        }
      }
    }
         */
      }
    </Layout>
  );
};

export default ComitatiLocaliArchive;

export const COMITATILOCALIARCHIVE_QUERY = graphql`
  query ComitatiLocaliArchiveQuery {
    datoCmsComitatiLocaliArchive {
      id
      label
      seoMetaTags {
        tags
      }
      coverImage {
        alt
        fluid(
          imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
          sizes: "(max-width: 1920px) 100vw, 100vw"
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      coverImageMobile {
        alt
        fluid(
          imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
          sizes: "(max-width: 767px) 100vw, 100vw"
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      title
      slug
      subtitle
      content
      contentAccordion
      visualComposer {
        ...BannerText
        ...CenteredNumbers
        ...Numbers
        ...Spacing
      }
    }
    datoCmsComitatiLocaliArchive {
      slug
      title
    }
    allDatoCmsRegioni(sort: {order: ASC, fields: title}) {
      nodes {
        slug
        title
        originalId
        mapInfo
        zone
      }
    }
  }
`;
