import React, {
  useRef,
  useState,
  useLayoutEffect,
  useEffect
} from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { InternalLink } from '../Link';
import SvgDesktop from './map.desktop';
import SvgTablet from './map.tablet';
import useMatchMedia from '../../hooks/match-media';
import Container from '../Container';

const mapRegions = {
  lombardia: {
    regionCode: 'lombardia'
  },
  campania: {
    regionCode: 'campania',
  },
  lazio: {
    regionCode: 'lazio',
  },
  sicilia: {
    regionCode: 'sicilia',
  },
  veneto: {
    regionCode: 'veneto',
  },
  'emilia-romagna': {
    regionCode: 'emilia-romagna',
  },
  piemonte: {
    regionCode: 'piemonte',
  },
  puglia: {
    regionCode: 'puglia',
  },
  toscana: {
    regionCode: 'toscana',
  },
  calabria: {
    regionCode: 'calabria',
  },
  sardegna: {
    regionCode: 'sardegna',
  },
  liguria: {
    regionCode: 'liguria',
  },
  marche: {
    regionCode: 'marche',
  },
  abruzzo: {
    regionCode: 'abruzzo',
  },
  'friuli-venezia-giulia': {
    regionCode: 'friuli-venezia-giulia',
  },
  'trentino-alto-adige': {
    regionCode: 'trentino-alto-adige',
  },
  umbria: {
    regionCode: 'umbria',
  },
  basilicata: {
    regionCode: 'basilicata',
  },
  molise: {
    regionCode: 'molise',
  },
  'valle-d-aosta': {
    regionCode: 'valle-d-aosta',
  }
};

const Map = ({ regions, marginBottom, marginTop }) => {
  const [selectedRegion, setSelectedRegion] = useState({});
  const [isTablet, setIsTablet] = useState(true);
  const popup = useRef(null);
  const [formattedRegions, setFormattedRegions] = useState({});
  useEffect(() => {
    regions.forEach((region) => {
      mapRegions[region.slug] = {
        ...mapRegions[region.slug],
        ...region
      };
    });
    setFormattedRegions(mapRegions);
  }, []);

  const handleMouseOver = (e, id) => {
    const overElement = document.getElementById(id).getBoundingClientRect();
    popup.current.style.left = `${overElement.left + (overElement.width / 2) + 8}px`;
    popup.current.style.top = `${overElement.top + (overElement.height / 2)}px`;
    const tempSelectedRegion = formattedRegions[id];
    setSelectedRegion(tempSelectedRegion);
  };
  useLayoutEffect(() => {
    const handleResize = () => {
      if (selectedRegion) {
        const overElement = document.querySelector('.map__element--active');
        if (overElement) {
          const {
            left,
            top,
            width,
            height
          } = overElement.getBoundingClientRect();
          popup.current.style.left = `${left + (width / 2) + 8}px`;
          popup.current.style.top = `${top + (height / 2)}px`;
        }
      }
    };
    handleResize();
    if (window !== 'undefined') {
      /** Adding a listener on window resize */
      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handleResize);
    }
    return () => {
      /** Destroying the listener on component unmount */
      if (window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleResize);
      }
    };
  }, []);

  useMatchMedia(setIsTablet, '(max-width: 1023px)');
  return (
    <Container
      className="map"
      marginBottom={marginBottom}
      marginTop={marginTop}
    >
      <div className="map__wrapper">
        <OutsideClickHandler onOutsideClick={() => setSelectedRegion({})}>
          <div
            ref={popup}
            className={`map__popup ${
              selectedRegion.regionCode ? 'map__popup--active' : ''
            }`}
          >
            <h4 className="heading-xs map__region-title">
              {selectedRegion.title}
            </h4>
            <p className="caption map__region-description">{selectedRegion.mapInfo}</p>
            <InternalLink url={`/comitati-locali/${selectedRegion.slug}`} label="scopri di più" kind="secondary" color="brand" />
          </div>
        </OutsideClickHandler>
        <div className="map__container">
          {
            isTablet
              ? <SvgTablet handleMouseOver={handleMouseOver} selectedRegion={selectedRegion} />
              : <SvgDesktop handleMouseOver={handleMouseOver} selectedRegion={selectedRegion} />
          }
        </div>
      </div>
    </Container>
  );
};

export default Map;
